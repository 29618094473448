import { axiosInstance as axios } from 'src/boot/axios-base'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    IsNumberValid(_, request) {
      return axios
        .post('IsNumberValid', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetAllCountryCode() {
      return axios
        .post('GetAllCountryCode')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
