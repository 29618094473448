import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getCompanyDetail({ rootGetters }) {
      return axios
        .post('CompanyDetail', {
 
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            const { CompanyName, ImageData } = response.data.Values
            return Promise.resolve({ CompanyName, ImageData })
          } else {
            return Promise.reject('取得公司資訊失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    getCompanyUserList({ rootGetters }, request) {
      return axios
        .post('CompanyUserList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    setCompanyLogo({ rootGetters }, ImageData) {
      const formData = new FormData()
      formData.append('CompanyLogo', ImageData)
      return axios
        .post('CompanySetlogo', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('公司Logo上傳成功')
          } else {
            return Promise.reject('公司Logo上傳失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    modifyCompanyDetail({ rootGetters }, request) {
      return axios
        .post('CompanyModify', {
          CompanyName: request.CompanyName
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('修改公司資訊成功')
          } else {
            return Promise.reject('修改公司資訊失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
