import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    OfficeList({ rootGetters }, request) {
      return axios
        .post('OfficeList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OfficeRead({ rootGetters }, request) {
      return axios
        .post('OfficeRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OfficeAdd({ rootGetters }, request) {
      return axios
        .post('OfficeAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OfficeModify({ rootGetters }, request) {
      return axios
        .post('OfficeModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OfficeDelete({ rootGetters }, request) {
      return axios
        .post('OfficeDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OfficeUserBind({ rootGetters }, request) {
      return axios
        .post('OfficeUserBind', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesSummaryDetail({ rootGetters }, request) {
      return axios
        .post('SalesSummaryDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
