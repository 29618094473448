const routes = [
  {
    path: '/',
    component: () => import('layouts/Frontend.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'company-register',
        name: 'company-register',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('pages/Frontend/Index.vue')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('pages/Frontend/News/News.vue')
      },
      {
        path: 'news_detail/:newsGuid',
        name: 'news_detail',
        component: () => import('pages/Frontend/News/NewsDetail.vue')
      },
      {
        path: 'about-us',
        name: 'about-us',
        component: () => import('pages/Frontend/AboutUs.vue')
      },
      {
        path: 'product_introduction/design',
        name: 'product_introduction.design',
        component: () => import('pages/Frontend/Production/ProductIntro_Design.vue')
      },
      {
        path: 'product_introduction/mes',
        name: 'product_introduction.mes',
        component: () => import('pages/Frontend/Production/ProductIntro_MES.vue')
      },
      {
        path: 'product_introduction/wil',
        name: 'product_introduction.wil',
        component: () => import('pages/Frontend/Production/ProductIntro_WIL.vue')
      },
      {
        path: 'board-portal',
        name: 'board-portal',
        component: () => import('pages/Frontend/Board/BoardIntro.vue')
      },
      {
        path: 'boards/:boardType/:page',
        name: 'boards',
        component: () => import('pages/Frontend/Board/BoardList.vue')
      },
      {
        path: '/board/:boardType/:boardSN',
        name: 'boardDetail',
        component: () => import('pages/Frontend/Board/BoardDetail.vue')
      },
      {
        path: 'course',
        name: 'course',
        component: () => import('pages/Frontend/Course/Course.vue')
      },
      {
        path: 'course_list/:playListGuid',
        name: 'course_list',
        component: () => import('pages/Frontend/Course/CourseList.vue')
      },
      {
        path: 'course_video/:videoGuid',
        name: 'course_video',
        component: () => import('pages/Frontend/Course/CourseVideo.vue')
      },
      {
        path: 'contact_us',
        name: 'contact_us',
        component: () => import('pages/Frontend/ContactUs.vue')
      },
      {
        path: 'design_works',
        name: 'design_works',
        component: () => import('pages/Frontend/DesignWork/DesignWorks.vue')
      },
      {
        path: 'design_work_detail/:caseGuid',
        name: 'design_work_detail',
        component: () => import('pages/Frontend/DesignWork/DesignWorkDetail.vue')
      },
      {
        path: 'activities',
        name: 'activities',
        component: () => import('pages/Frontend/Activity/Activities.vue')
      },
      {
        path: 'activity_detail/:activityGuid',
        name: 'activity_detail',
        component: () => import('pages/Frontend/Activity/ActivityDetail.vue')
      },
      {
        path: 'activity_apply/:activityGuid',
        name: 'activity_apply',
        component: () => import('pages/Frontend/Activity/ActivityApply.vue')
      },
      {
        path: 'mobile-validation',
        name: 'mobile-validation',
        component: () => import('pages/Frontend/MobileValidation.vue')
      },
      {
        path: 'sign-up-validation',
        name: 'sign-up-validation',
        component: () => import('pages/Frontend/SignUpValidation.vue')
      },
      {
        path: 'save-design-order',
        name: 'save-design-order',
        component: () => import('pages/Frontend/DesignOrder.vue')
      },
      {
        path: 'whole-house-quote',
        name: 'whote-house-quote',
        component: () => import('pages/Frontend/WholeHouseQuote.vue'),
        meta: { requiredLogin: true, limitAuth: ['Designer'] }
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import('layouts/Backend.vue'),
    children: [
      {
        path: '',
        name: 'backend_home',
        component: () => import('pages/Backend/Index.vue'),
        meta: { requiredLogin: true }
      },
      {
        path: 'company_management',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: 'basic',
            name: 'basic_management',
            component: () => import('pages/Backend/Company/BasicManagement.vue'),
            meta: { requiredLogin: true }
          },
          {
            path: 'member',
            name: 'user_management',
            component: () => import('pages/Backend/Company/MemberManagement.vue'),
            meta: { requiredLogin: true }
          },
          {
            path: 'office',
            name: 'office_management',
            component: () => import('pages/Backend/Company/OfficeManagement.vue'),
            meta: { requiredLogin: true }
          }
        ]
      },
      {
        path: 'user',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            name: 'backend_user',
            component: () => import('pages/Backend/User/User.vue'),
            meta: { requiredLogin: true }
          },
          {
            path: 'modify-user-email',
            name: 'modify-user-email',
            component: () => import('pages/Backend/User/ModifyUserEmailPage.vue'),
            meta: {
              requiredLogin: false
            }
          },
          {
            path: 'active-user-email',
            name: 'active-user-email',
            component: () => import('pages/Backend/User/ActiveUserEmailPage.vue'),
            meta: {
              requiredLogin: false
            }
          }
        ]
      },
      {
        path: 'backend_order',
        redirect: { name: 'backend_order.list' },
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            redirect: {
              name: 'backend_order.list'
            }
          },
          {
            path: 'order',
            name: 'backend_order.list',
            component: () => import('pages/Backend/Order/OrderListPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'calc',
            name: 'backend_order.calc',
            component: () => import('pages/Backend/Order/OrderCalcPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'post',
            name: 'backend_order.post',
            component: () => import('pages/Backend/Order/OrderPostPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'read/:orderID',
            name: 'backend_order.read',
            component: () => import('pages/Backend/Order/OrderReadPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'edit/:orderID',
            name: 'backend_order.edit',
            component: () => import('pages/Backend/Order/OrderEditPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'whole-house-item',
            name: 'backend_order.wholeHouseQuoteItem',
            component: () => import('pages/Backend/Order/WholeHouseQuoteItemPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          },
          {
            path: 'whole-house-quote-rule',
            name: 'backend_order.wholeHouseQuoteRule',
            component: () => import('pages/Backend/Order/WholeHouseQuoteRulePage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Designer']
            }
          }
        ]
      },
      {
        path: 'backend_manufacturer',
        redirect: { name: 'backend_manufacturer.order.list' },
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: 'item',
            name: 'backend_manufacturer.item',
            component: () => import('pages/Backend/Manufacturer/ManufacturerItemPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'discount',
            name: 'backend_manufacturer.discount',
            component: () => import('pages/Backend/Manufacturer/ManufacturerDiscountPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'order',
            name: 'backend_manufacturer.order.list',
            component: () => import('pages/Backend/Manufacturer/ManufacturerOrderListPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'offer/:orderID',
            name: 'backend_manufacturer.order.offer',
            component: () => import('pages/Backend/Manufacturer/ManufacturerOrderOfferPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'read/:orderID',
            name: 'backend_manufacturer.order.read',
            component: () => import('pages/Backend/Manufacturer/ManufacturerOrderReadPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'quote',
            name: 'backend_manufacturer.order.read.quote',
            component: () => import('pages/Backend/Manufacturer/ManufacturerOrderQuotePage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            },
            props: (route) => route.query
          },
          {
            path: 'minimal-decoration-solution',
            name: 'backend_manufacturer.minimalDecorationSolution',
            component: () => import('pages/Backend/Manufacturer/MinimalDecorationSolutionPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'minimal-decoration-item',
            name: 'backend_manufacturer.minimalDecorationItem',
            component: () => import('pages/Backend/Manufacturer/MinimalDecorationItemPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory', 'MinimalDecoration']
            }
          },
          {
            path: 'minimal-decoration-price-rule',
            name: 'backend_manufacturer.minimalDecorationPriceRule',
            component: () => import('src/pages/Backend/Manufacturer/MinimalDecorationPriceRulePage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory', 'MinimalDecoration']
            }
          },
          {
            path: 'connectorsolution',
            name: 'backend_manufacturer.connectorsolution',
            component: () => import('pages/Backend/Manufacturer/ManufacturerConnectorSolutionPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'extraprice',
            name: 'backend_manufacturer.extraprice',
            component: () => import('pages/Backend/Manufacturer/ManufacturerExtraPricePage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'whole-house-item',
            name: 'backend_manufacturer.wholeHouseQuoteItem',
            component: () => import('pages/Backend/Manufacturer/ManufacturerWholeHouseQuoteItemPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          },
          {
            path: 'whole-house-quote-rule',
            name: 'backend_manufacturer.wholeHouseQuoteRule',
            component: () => import('pages/Backend/Manufacturer/ManufacturerWholeHouseQuoteRulePage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Factory']
            }
          }
        ]
      },
      {
        path: 'home-mes',
        redirect: { name: 'homeMes.workOrderMgmt' },
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: 'orders-summary',
            name: 'homeMes.ordersSummary',
            component: () => import('pages/Backend/HomeMes/OrdersSummaryPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'storage-management',
            name: 'homeMes.storageMgmt',
            component: () => import('pages/Backend/HomeMes/StorageMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'work-order',
            name: 'homeMes.workOrderMgmt',
            component: () => import('pages/Backend/HomeMes/WorkOrderMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          // {
          //   path: 'work-order/:workOrderId',
          //   name: 'homeMes.workOrderProgress',
          //   component: () => import('pages/Backend/HomeMes/WorkOrderProgressPage.vue'),
          //   meta: {
          //     requiredLogin: true,
          //     limitAuth: ['Mes']
          //   },
          //   props: (route) => ({
          //     ...route.params
          //   })
          // },
          // {
          //   path: 'work-order/:workOrderId/cabinet/:cabinetId',
          //   name: 'homeMes.cabinetSmallBoardProgress',
          //   component: () => import('pages/Backend/HomeMes/CabinetSmallBoardProgressPage.vue'),
          //   meta: {
          //     requiredLogin: true,
          //     limitAuth: ['Mes']
          //   },
          //   props: (route) => ({
          //     ...route.params
          //   })
          // },
          {
            path: 'work-order/board-list',
            name: 'homeMes.workOrderMgmt.boardList',
            component: () => import('src/pages/Backend/HomeMes/WorkOrderBoardListPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] },
            props: (route) => route.query
          },
          {
            path: 'work-order/board-list/check',
            name: 'homeMes.workOrderMgmt.boardList.check',
            component: () => import('src/pages/Backend/HomeMes/WorkOrderBoardCheckResultPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] },
            props: (route) => route.query
          },
          {
            path: 'manual-work-order',
            name: 'homeMes.manualWorkOrderMgmt',
            component: () => import('pages/Backend/HomeMes/ManualWorkOrderMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'manual-work-order/board-list',
            name: 'homeMes.manualWorkOrderMgmt.boardList',
            component: () => import('src/pages/Backend/HomeMes/ManualWorkOrderBoardListPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] },
            props: (route) => route.query
          },
          {
            path: 'order-packing',
            name: 'homeMes.orderPackingMgmt',
            component: () => import('src/pages/Backend/HomeMes/OrderPackingMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'raw-board',
            name: 'homeMes.rawBoardMgmt',
            component: () => import('src/pages/Backend/HomeMes/RawBoardMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'banding-striple',
            name: 'homeMes.bandingStripleMgmt',
            component: () => import('src/pages/Backend/HomeMes/BandingStripleMgmtPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          },
          {
            path: 'internal-defect',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.internalDefectList',
                component: () => import('pages/Backend/HomeMes/InternalDefectListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              },
              {
                path: 'read/:id',
                name: 'homeMes.internalDefectRead',
                component: () => import('pages/Backend/HomeMes/InternalDefectReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'external-defect',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.externalDefectList',
                component: () => import('pages/Backend/HomeMes/ExternalDefectListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              },
              {
                path: 'read/:id',
                name: 'homeMes.externalDefectRead',
                component: () => import('pages/Backend/HomeMes/ExternalDefectReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'productivity',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.productivity',
                component: () => import('pages/Backend/HomeMes/ProductivityPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'sphere-packing-mgmt',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.manualSpherePacking',
                component: () => import('src/pages/Backend/HomeMes/SpherePackingMgmtPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'machine-fault',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.machineFaultList',
                component: () => import('pages/Backend/HomeMes/MachineFaultListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              },
              {
                path: 'read/:id',
                name: 'homeMes.machineFaultRead',
                component: () => import('pages/Backend/HomeMes/MachineFaultReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'expense',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.expenseMgmt',
                component: () => import('pages/Backend/HomeMes/ExpenseMgmtPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'abnormal-board',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'homeMes.abnormalBoardList',
                component: () => import('pages/Backend/HomeMes/abnormalBoardListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Mes']
                }
              }
            ]
          },
          {
            path: 'workstation-packing',
            name: 'homeMes.workStationPacking',
            component: () => import('src/pages/Backend/HomeMes/WorkStationPackingPage.vue'),
            meta: { requiredLogin: true, limitAuth: ['Mes'] }
          }
        ]
      },
      {
        path: 'lncmac-platform',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            redirect: { name: 'lncmacPlatform.orders' }
          },
          {
            path: 'orders',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.orders',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayOrderListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'read/:id',
                name: 'lncmacPlatform.order.read',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayOrderReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'cabinet-publish',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.cabinetPublish',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetPublishPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'post',
                name: 'lncmacPlatform.cabinetPublish.post',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetPublishPostPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'read/:cabinetId',
                name: 'lncmacPlatform.cabinetPublish.read',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetPublishEditPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                },
                props: true
              },
              {
                path: 'tag',
                name: 'lncmacPlatform.cabinetPublish.tag',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetPublishTagPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'sub-cabinet-type',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.subCabinetType',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaySubCabinetTypePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          { 
            path: 'cabinet-type',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.cabinetType',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetTypePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'cabinet-big-type',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.cabinetBigType',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetBigTypePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'cabinet-style',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.cabinetStyle',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayCabinetStylePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'information',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.information',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayInformation.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'hot-commodity',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.hotCommodity',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayHotCommodity.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'bg-orders',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.bgOrders',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaybgOrders.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'detail/:orderId',
                name: 'lncmacPlatform.bgOrders.detail',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaybgOrderDetail.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                },
                props: true
              },
              {
                path: 'abnormal-add/:mark/:businessOrderId',
                name: 'lncmacPlatform.bgOrders.abnormalAdd',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayAbnormalAddPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'examine',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.examine',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayOrderExaminePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'detail/:orderId',
                name: 'lncmacPlatform.examine.detail',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaybgOrderDetail.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                },
                props: true
              },
              {
                path: 'abnormal-add/:mark/:businessOrderId',
                name: 'lncmacPlatform.examine.abnormalAdd',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayAbnormalAddPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'split-order',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.splitOrder',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaySplitOrderPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              { 
                path: 'offer/:OrderId',
                name: 'lncmacPlatform.splitOrder.offer',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaySplitOrderOfferPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'boardBrand',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.boardBrand',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayBoardBrandPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: ':brandId',
                name: 'lncmacPlatform.boardBrand.read',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayBoardBrandReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                },
                props: true
              }
            ]
          },
          {
            path: 'dealer',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.dealer',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayDealerPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              },
              {
                path: 'detail/:orderId',
                name: 'lncmacPlatform.dealer.detail',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwaybgOrderDetail.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                },
                props: true
              },
              {
                path: 'abnormal-add/:mark/:businessOrderId',
                name: 'lncmacPlatform.dealer.abnormalAdd',
                component: () => import('pages/Backend/LncmacPlatform/ClosetHighwayAbnormalAddPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          },
          {
            path: 'remoteDesign',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'lncmacPlatform.remoteDesign',
                component: () => import('src/pages/Backend/LncmacPlatform/ClosetHighwayRemoteDesignPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['ClosetHighway']
                }
              }
            ]
          }
        ]
      },
      {
        path: 'workers',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: '',
            redirect: { name: 'workers.order' }
          },
          {
            path: 'order',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'workers.order',
                component: () => import('pages/Backend/Workers/WorkersOrderListPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              },
              {
                path: 'pick',
                name: 'workers.order.pick',
                component: () => import('pages/Backend/Workers/WorkersOrderPickPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              },
              {
                path: 'pick/read/:orderId',
                name: 'workers.order.pick.read',
                component: () => import('pages/Backend/Workers/WorkersOrderPickReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              },
              {
                path: 'add/:orderId',
                name: 'workers.order.add',
                component: () => import('pages/Backend/Workers/WorkersOrderAddPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              },
              {
                path: 'read/:assemblyOrderId',
                name: 'workers.order.read',
                component: () => import('pages/Backend/Workers/WorkersOrderReadPage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              }
            ]
          },
          {
            path: 'date',
            component: { render: (h) => h('router-view') },
            children: [
              {
                path: '',
                name: 'workers.DatePage',
                component: () => import('pages/Backend/Workers/WorkersDatePage.vue'),
                meta: {
                  requiredLogin: true,
                  limitAuth: ['Worker']
                }
              }
            ]
          }
        ]
      },
      {
        path: 'sales',
        component: { render: (h) => h('router-view') },
        children: [
          {
            path: 'catagory',
            name: 'sales.catagory',
            component: () => import('pages/Backend/Sales/SalesTaskPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          },
          {
            path: 'board',
            name: 'sales.board',
            component: () => import('src/pages/Backend/Sales/SalesBoardPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          },
          {
            path: 'residentboard',
            name: 'residentsales.board',
            component: () => import('src/pages/Backend/Sales/ResidentSalesBoardPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          },
          {
            path: 'dairy_list',
            name: 'sales.dairy.list',
            component: () => import('src/pages/Backend/Sales/SalesDairyListPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          },
          {
            path: 'dairy_post',
            name: 'sales.dairy.post',
            component: () => import('pages/Backend/Sales/SalesDairyPostPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          },
          {
            path: 'dairy_read',
            name: 'sales.dairy.read',
            component: () => import('pages/Backend/Sales/SalesDairyReadPage.vue'),
            meta: {
              requiredLogin: true,
              limitAuth: ['Sales']
            }
          }
        ]
      }
    ]
  }
  // {
  //   path: '/backend-guide',
  //   component: () => import('layouts/Backend.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'backend_guide',
  //       component: () => import('pages/Backend/contact.vmd'),
  //       meta: { requiredLogin: true }
  //     }
  //   ]
  // }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    redirect: { name: 'home' }
  })
}

export default routes
