import { Notify } from 'quasar'

export default ({ app, router, store, Vue }) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('UserStore/checkLoginUserInfo').then(() => {
      if (store.getters['UserStore/getUserIsLogin'] === true) {
        if (store.getters['UserStore/getLoginUserIsTermsAgreed'] !== true) {
          if (to.name !== 'terms') {
            return next({
              name: 'terms',
              query: {
                redirect: to.fullPath
              }
            })
          }
        }
      }

      if (to.meta && to.meta.requiredLogin === true) {
        if (store.getters['UserStore/getUserIsLogin'] !== true) {
          Notify.create({
            type: 'negative',
            message: '請先登入'
          })

          next({
            name: 'home',
            query: {
              action: 'login',
              redirect: to.fullPath
            }
          })
          return
        } else if (to.meta.limitAuth) {
          for (let i = 0; i < to.meta.limitAuth.length; i++) {
            let auth = to.meta.limitAuth[i]

            if (store.getters['UserStore/getLoginUserInfo'].UserPermission[auth] !== true) {
              Notify.create({
                type: 'negative',
                message: '帳號權限不足'
              })

              next({
                name: 'home',
                query: {
                  action: 'login',
                  redirect: to.fullPath
                }
              })
              return
            }
          }
        }
      }

      next()
    })
  })
}
