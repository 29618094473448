import { axiosInstance as axios } from '../../boot/axios'
import { Store } from '../'

export default {
  namespaced: true,
  state: {
    loginUserInfo: null
  },
  getters: {
    getLoginUserInfo(state) {
      if (!state.loginUserInfo) {
        return ''
      }
      return state.loginUserInfo
    },
    getUserIsLogin(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return true
    },
    getLoginUserIsTermsAgreed(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.IsTremsAgreed
    },
    getLoginUserIsCompanyManagement(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.CompanyManagement
    },
    getUserCompanyPermission(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.UserCompanyPermission
    },
    getLoginUserIsDesigner(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Designer
    },
    getLoginUserIsFactory(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Factory
    },
    getLoginUserFactoryPermission(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.UserFactoryPermission
    },
    getLoginUserMesPermission(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.UserMesPermission
    },
    getLoginUserSalesPermission(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.UserSalesPermission
    },
    getLoginUserIsMes(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Mes
    },
    getLoginUserIsClosetHighway(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.ClosetHighway
    },
    getLoginUserIsWorker(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Worker
    },
    getLoginUserIsSales(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Sales
    },
    getLoginUserIsOversea(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.Oversea
    },
    getLoginUserIsAllowV5(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.DesignV5
    },
    getLoginUserIsAllowSakuraSpecialDesignVersion(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.SpecialDesignVersion
    },
    getLoginUserIsMinimalDecoration(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.MinimalDecoration
    },
    getLoginUserIsEstimateBom(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.EstimateBom
    },
    getLoginUserIsEstimateDetail(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.EstimateDetail
    },
    getLoginUserIsSkCustomEstimate(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.SkCustomEstimate
    },
    getLoginUserIsStorageManagement(state) {
      if (!state.loginUserInfo) {
        return false
      }

      return state.loginUserInfo.UserPermission.StorageManagement
    },
    getLoginUserLang(state) {
      if (!state.loginUserInfo) {
        return ''
      }

      return state.loginUserInfo.UserLanguage
    }
  },
  mutations: {
    changeLoginUserInfo(state, userInfo) {
      state.loginUserInfo = userInfo
    },
    changeLoginUserImage(state, userImage) {
      state.loginUserInfo.ImageMedia = userImage.ImageMedia
      state.loginUserInfo.ImageData = userImage.ImageData
    }
  },
  actions: {
    UserValid({ getters, commit }, request) {
      return axios
        .post('UserValid', {
          UserAcc: request.UserAcc,
          UserPwd: request.UserPwd,
          LoginType: 2
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            if (response.data.Values.UserType >= 1) {
              commit('changeLoginUserInfo', response.data.Values)

              localStorage.removeItem('store')
              localStorage.setItem('store', JSON.stringify(Store.state))

              if (!response.data.Values.IsTremsAgreed) {
                return Promise.resolve({ needAgreeTerms: true })
              }
            }
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === 1) {
            return Promise.resolve({
              needVerify: true,
              UserPwd: request.UserPwd,
              UserAcc: request.UserAcc
            })
          } else {
            return Promise.reject('帳號密碼錯誤，請重新登入！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserVertify({ getters, commit }, request) {
      return axios
        .post('UserVertify', {
          UserAcc: request.UserAcc,
          UserPwd: request.UserPwd
        })
        .then((response) => {
          if (response.data.Values !== null && response.data.Values.ReqInt === 0) {
            commit('changeLoginUserInfo', response.data.Values)

            localStorage.removeItem('store')
            localStorage.setItem('store', JSON.stringify(Store.state))

            if (!response.data.Values.IsTremsAgreed) {
              return Promise.resolve({ needAgreeTerms: true })
            }

            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('驗證碼輸入失敗，請重新輸入！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserAgreeTerms({ getters, dispatch }, request) {
      return axios
        .post('UserAgreeTerms')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            dispatch('checkLoginUserInfo')
            return Promise.resolve('已同意服務條款')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    logoutUser({ getters, commit }) {
      return axios
        .post('UserOut')
        .then((response) => {
          commit('changeLoginUserInfo', null)

          localStorage.removeItem('store')
          localStorage.setItem('store', JSON.stringify(Store.state))

          return Promise.resolve('登出成功！')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    checkUserAccCanUse({ getters, commit }, request) {
      return axios
        .post('UserAcc', {
          UserAcc: request.UserAcc
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('帳號可用')
          } else {
            return Promise.reject('帳號已被註冊')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserEmailRepeat({ getters, commit }, request) {
      return axios
        .post('UserEmailRepeat', {
          UserEmail: request.UserEmail
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('信箱可用')
          } else {
            return Promise.reject('信箱已被註冊')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserMobileRepeat(_, request) {
      return axios
        .post('UserMobileRepeat', {
          UserMobile: request.UserMobile,
          CountryCode: request.CountryCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('手機可用')
          } else {
            return Promise.reject('手機已被註冊')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    signupUser(_, request) {
      return axios
        .post('UserAdd', {
          UserAcc: request.UserAcc,
          UserPwd: request.UserPwd,
          UserEmail: request.UserEmail,
          UserMobile: request.UserMobile,
          CountryCode: request.CountryCode,
          UserPhone: request.UserPhone,
          UserCompany: request.UserCompany,
          SpecificCompanyRegisterId: request.SpecificCompanyRegisterId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('註冊失敗：' + response.data.RC)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    signUpValidation({ getters, commit, dispatch }, request) {
      return axios
        .post('UserAddConfirm', {
          UserActivationGuid: request.UserActivationGuid,
          ActivationCode: request.ActivationCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('帳號驗證成功，請重新登入！')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.resolve('帳號已經驗證，請直接登入！')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('設計平台註冊失敗，請聯絡寶元智造人員')
          } else {
            return Promise.reject('驗證失敗，請確認驗證碼是否輸入正確，或重發驗證簡訊！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserMobileReSend(_, request) {
      return axios
        .post('UserMobileReSend', request)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('成功寄出，請至手機查看驗證簡訊！')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('您的手機門號已設定「拒收簡訊」服務，請洽手機門號客服人員關閉！')
          } else {
            return Promise.reject('帳號錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    resetUserPassword({ getters, commit }, request) {
      return axios
        .post('UserPwd', {
          UserAcc: request.UserAcc
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('請至手機簡訊，查看重設後的密碼！')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('已超過當月上限次數，請聯絡寶元智造相關人員！')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('您的手機門號已設定「拒收簡訊」服務，請洽手機門號客服人員關閉！')
          } else {
            return Promise.reject('帳號錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserMobileModify({ getters, commit }, request) {
      return axios
        .post('UserMobileModify', {
          UserPwd: request.UserPwd,
          UserMobile: request.UserMobile,
          CountryCode: request.CountryCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('驗證碼已發送至手機簡訊，請輸入驗證碼完成修改')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('您的手機門號已設定「拒收簡訊」服務，請洽手機門號客服人員關閉！')
          } else {
            return Promise.reject('發生錯誤！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserMobileModifyConfirm({ getters, commit, dispatch }, request) {
      return axios
        .post('UserMobileModifyConfirm', {
          ActivationCode: request.ActivationCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('手機號碼修改成功！')
          } else {
            return Promise.reject('驗證失敗，請確認驗證碼是否輸入正確，或重發驗證簡訊！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserEmailModify({ getters, commit }, request) {
      return axios
        .post('UserEmailModify', {
          UserPwd: request.UserPwd,
          UserEmail: request.UserEmail
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('請至信箱查看修改驗證信，完成信箱修改流程！')
          } else {
            return Promise.reject('發生錯誤！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserEmailModifyConfirm({ getters, commit }, request) {
      return axios
        .post('UserEmailModifyConfirm', {
          UserEmail: request.UserEmail,
          UserEmailActivationCode: request.UserEmailActivationCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('信箱修改完成！')
          } else {
            return Promise.reject('信箱修改驗證錯誤！ 請重新發送驗證信')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserEmailActivate({ getters, commit }, request) {
      return axios
        .post('UserEmailActivate', {
          UserEmail: request.UserEmail
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('請至信箱查看驗證信，完成信箱驗證流程！')
          } else {
            return Promise.reject('發生錯誤！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    UserEmailActivateConfirm({ getters, commit }, request) {
      return axios
        .post('UserEmailActivateConfirm', {
          UserEmail: request.UserEmail,
          UserEmailActivationCode: request.UserEmailActivationCode
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('信箱驗證完成！')
          } else {
            return Promise.reject('信箱驗證錯誤！ 請重新發送驗證信')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    checkLoginUserInfo({ getters, commit }) {
      if (!getters.getLoginUserInfo) {
        return Promise.resolve()
      }
      return axios
        .post('UserStatus', {})
        .then((response) => {
          if (response.data.Values.ReqInt !== 0) {
            commit('changeLoginUserInfo', null)
          } else {
            if (response.data.Values.UserType === 1 || response.data.Values.UserType > 9) {
              commit('changeLoginUserInfo', response.data.Values)
            } else {
              commit('changeLoginUserInfo', null)
            }
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
        .finally(() => {
          return Promise.resolve()
        })
    },
    getUserStatus({ getters, commit }, request) {
      return axios
        .post('UserStatus', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('存取錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    getActivationInfo({ getters, commit }, request) {
      return axios
        .post('UserActivationInfo', {
          UserActivationGuid: request.UserActivationGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('存取錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    getLoginUserDetail({ getters, commit }, request) {
      if (!getters.getUserIsLogin) {
        return Promise.reject()
      }

      return axios
        .post('UserDetail')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('存取失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BuyLimit_TrialApply({ getters, commit }, request) {
      if (!getters.getUserIsLogin) {
        return false
      }

      return axios
        .post('BuyLimit', {
          UserName: request.UserName,
          UserEmail: request.UserEmail,
          UserPhone: request.UserPhone,
          UserMobile: request.UserMobile
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('申請失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    checkLoginUserPassword({ getters, commit }, request) {
      if (!getters.getUserIsLogin) {
        return false
      }

      return axios
        .post('UserCfmPwd', {
          UserPwd: request.UserPwd
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('密碼正確')
          } else {
            return Promise.reject('與原密碼不符')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    changeLoginUserPwd({ getters, commit }, request) {
      if (!getters.getUserIsLogin) {
        return false
      }

      return axios
        .post('UserSetPwd', {
          UserOgnPwd: request.UserOgnPwd,
          UserPwd: request.UserPwd
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('密碼修改完成！')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('已超過當月上限次數，請聯絡寶元智造相關人員！')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('您的手機門號已設定「拒收簡訊」服務，請洽手機門號客服人員關閉！')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('舊密碼與新密碼不可相同！')
          } else {
            return Promise.reject('變更密碼失敗！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    changeLoginUserImage({ getters, commit }, UserImage) {
      if (!getters.getUserIsLogin) {
        return false
      }
      const formData = new FormData()
      formData.append('UserImage', UserImage)
      return axios
        .post('UserSetImage', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return axios.post('UserGetImage')
          } else {
            return Promise.reject('大頭貼上傳失敗')
          }
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            commit('changeLoginUserImage', response.data.Values)
          }
          return Promise.resolve('大頭貼上傳成功！')
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    changeLoginUserDetail({ getters, commit }, request) {
      if (!getters.getUserIsLogin) {
        return false
      }

      return axios
        .post('UserModify', {
          UserName: request.UserName,
          UserCity: request.UserCity,
          UserAddress: request.UserAddress,
          UserNick: request.UserNick,
          UserBirth: request.UserBirth,
          UserGender: request.UserGender,
          UserCompany: request.UserCompany,
          UserTitle: request.UserTitle,
          UserMobile: request.UserMobile,
          UserPhone: request.UserPhone,
          UserLine: request.UserLine,
          UserWeChat: request.UserWeChat,
          UserFB: request.UserFB,
          UserIntro: request.UserIntro
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('已修改帳戶資料')
          } else {
            return Promise.reject('修改失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    disableLoginUser({ getters, commit, actions }) {
      if (!getters.getUserIsLogin) {
        return false
      }

      return axios
        .post('UserDisable')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            commit('changeLoginUserInfo', null)
            return Promise.resolve('已停用帳戶')
          } else {
            return Promise.reject('停用失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GotoDesign({ getters, commit, actions }, request) {
      return axios
        .post('GotoDesign', {
          DesignVersion: request.DesignVersion
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values.RedirectURL)
          } else {
            return Promise.reject('已從其它電腦登入，請重新登入並進行跳轉！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GotoDesignOversea({ getters, commit, actions }, request) {
      return axios
        .post('OverseaSSO', {
          DesignVersion: request.DesignVersion
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('跳轉設計頁面錯誤!')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ContactLNC({ getters, commit, actions }, request) {
      return axios
        .post('ContactLNC', {
          UserName: request.UserName,
          UserCompany: request.UserCompany,
          UserMobile: request.UserMobile,
          UserEmail: request.UserEmail,
          ContactType: request.ContactType,
          UserContent: request.UserContent
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CompanyInfo({ getters, commit, actions }, request) {
      return axios
        .post('CompanyInfo', {
          CompanyShortName: request.CompanyShortName,
          SpecificCompanyRegisterId: request.SpecificCompanyRegisterId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetUserLanguage({ getters }, request) {
      return axios
        .post('SetUserLaguage', {
          UserLanguage: request.UserLanguage
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
