import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    CaseList({ commit, rootGetters }, request) {
      return axios
        .post('CaseList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CaseRead({ commit, rootGetters }, request) {
      return axios
        .post('CaseRead', {
          CaseGuid: request.CaseGuid
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CaseLike({ commit, rootGetters }, request) {
      return axios
        .post('CaseLike', {
          CaseGuid: request.CaseGuid,
          CaseLike: request.CaseLike
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('失敗！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
