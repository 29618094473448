import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    MESOrderList({ commit, rootGetters }, request) {
      return axios
        .post('OrderList', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderRead({ commit, rootGetters }, request) {
      return axios
        .post('OrderRead', {
          OrderID: request.OrderID
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDemandFile({ commit, rootGetters }, request) {
      return axios
        .post(
          'DemandFile',
          {
            OrderID: request.OrderID,
            DemandID: request.DemandID,
            FileType: request.FileType
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESManufacturerList({ commit, rootGetters }, request) {
      return axios
        .post('ManufacturerList')
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESCabinetList({ commit, rootGetters }, request) {
      return axios
        .post('MESCabinetList', {
          VjiaSave: request.VjiaSave
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderUpload({ commit, rootGetters }, request) {
      let formData = new FormData()
      formData.append('OrderTitle', request.OrderTitle)
      formData.append('ManufacturerID', request.ManufacturerID)
      request.Demands.forEach((demand, index) => {
        formData.append(`Demands[${index}].DemandTitle`, demand.DemandTitle)
        formData.append(`Demands[${index}].DemandCount`, demand.DemandCount)
        formData.append(`Demands[${index}].DemandRemark`, demand.DemandRemark)
        formData.append(`Demands[${index}].DesignerNoteFile`, demand.DesignerNoteFile)
        formData.append(`Demands[${index}].DesignSubOrderGuid`, demand.DesignSubOrderGuid)
      })

      return axios
        .post('OrderUpload', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('新增成功！')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('設計資料異常！')
          } else {
            return Promise.reject('新增失敗！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderModify({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('OrderID', request.OrderID)
      formData.append('OrderTitle', request.OrderTitle)
      formData.append('ManufacturerID', request.ManufacturerID)

      request.AddDemands.forEach((demand, index) => {
        formData.append(`AddDemands[${index}].DemandTitle`, demand.DemandTitle)
        formData.append(`AddDemands[${index}].DemandCount`, demand.DemandCount)
        formData.append(`AddDemands[${index}].DemandRemark`, demand.DemandRemark)
        formData.append(`AddDemands[${index}].DesignerNoteFile`, demand.DesignerNoteFile)
        formData.append(`AddDemands[${index}].DesignSubOrderGuid`, demand.DesignSubOrderGuid)
      })

      request.ModifyDemands.forEach((demand, index) => {
        formData.append(`ModifyDemands[${index}].DemandID`, demand.DemandID)
        formData.append(`ModifyDemands[${index}].DemandTitle`, demand.DemandTitle)
        formData.append(`ModifyDemands[${index}].DemandCount`, demand.DemandCount)
        formData.append(`ModifyDemands[${index}].DemandRemark`, demand.DemandRemark)
        formData.append(`ModifyDemands[${index}].DesignerNoteFile`, demand.DesignerNoteFile)
        formData.append(`ModifyDemands[${index}].DeleteDesignerNoteFile`, demand.DeleteDesignerNoteFile)
      })

      request.DeleteDemands.forEach((demand, index) => {
        formData.append(`DeleteDemands[${index}]`, demand)
      })

      return axios
        .post('OrderModify', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderEstimate({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('ManufacturerID', request.ManufacturerID)

      if (request.DesignSubOrderGuids.length > 0) {
        request.DesignSubOrderGuids.forEach((guid) => {
          formData.append('DesignSubOrderGuids', guid)
        })
      }

      if (request.DesignXmls) {
        request.DesignXmls.forEach((xml) => {
          formData.append('DesignXmls', xml)
        })
      }

      if (request.BoardConfigGuid) formData.append('BoardConfigGuid', request.BoardConfigGuid)
      if (request.HingeSolutionGuid) formData.append('HingeSolutionGuid', request.HingeSolutionGuid)
      if (request.ExtendedRailSolutionGuid) formData.append('ExtendedRailSolutionGuid', request.ExtendedRailSolutionGuid)
      if (request.HiddenRailSolutionGuid) formData.append('HiddenRailSolutionGuid', request.HiddenRailSolutionGuid)
      if (request.WallCabinetSolutionGuid) formData.append('WallCabinetSolutionGuid', request.WallCabinetSolutionGuid)
      if (request.AdjustableFootSolutionGuid) formData.append('AdjustableFootSolutionGuid', request.AdjustableFootSolutionGuid)
      if (request.FlipSolutionGuid) formData.append('FlipSolutionGuid', request.FlipSolutionGuid)

      return axios
        .post('OrderEstimate', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values && response.data.Values.ReqInt === -2) {
            return Promise.reject('XML格式錯誤')
          } else if (response.data.Values && response.data.Values.ReqInt === -3) {
            return Promise.reject('取得設計檔案失敗，請聯繫寶元智造人員')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDownloadEstimate({ commit, rootGetters }, request) {
      let formData = new FormData()

      formData.append('EstimateID', request.EstimateID)
      formData.append('Gpm', request.Gpm)
      formData.append('EstimateType', request.EstimateType)
      if (request.EstimateType === 2) {
        formData.append('BomArea', request.BomArea)
      }

      request.CabinetImageFiles.forEach((cabinet, index) => {
        formData.append(`CabinetImageFiles[${index}].CabinetUID`, cabinet.CabinetUID)
        formData.append(`CabinetImageFiles[${index}].ImageFile`, cabinet.ImageFile)
      })

      return axios
        .post('DownloadEstimate', formData, { responseType: 'blob' })
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadSakuraEstimate({ rootGetters }, request) {
      return axios
        .post(
          'DownloadSakuraEstimate',
          {
            EstimateID: request.EstimateID,
            Gpm: request.Gpm
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderInquiry({ commit, rootGetters }, request) {
      return axios
        .post('OrderInquiry', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderConfirm({ commit, rootGetters }, request) {
      return axios
        .post('OrderConfirm', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態錯誤')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('期望交貨日期錯誤，不可小於三天後')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderCancel({ commit, rootGetters }, request) {
      return axios
        .post('OrderCancel', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('訂單狀態不能為生產中')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderDelete({ commit, rootGetters }, request) {
      return axios
        .post('OrderDelete', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrderCopy({ commit, rootGetters }, request) {
      return axios
        .post('OrderCopy', {
          OrderID: request.OrderID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDownloadQuotation({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadQuotation',
          {
            OrderID: request.OrderID
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSaveDesignOrder({ commit, rootGetters }, request) {
      return axios
        .post('SaveDesignOrder', {
          DesignOrderGuid: request.DesignOrderGuid,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('該檔案已儲存過了！')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('取得設計檔案異常！')
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('存取失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESSaveAndEstimateDesignOrder({ commit, rootGetters }, request) {
      return axios
        .post('SaveAndEstimateDesignOrder', {
          DesignOrderGuid: request.DesignOrderGuid,
          Note: request.Note
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject({
              reqInt: response.data.Values.ReqInt,
              errorMsg: '該檔案已儲存過了！'
            })
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('取得設計檔案異常！')
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('存取失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESIsDesignProjectMatchOwner({ rootGetters }, request) {
      return axios
        .post('IsDesignProjectMatchOwner', {
          DesignOrderGuid: request.DesignOrderGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDesignProjectList({ rootGetters }, request) {
      return axios
        .post('DesignProjectList', {
          ProjectName: request && request.ProjectName ? request.ProjectName : null,
          OrderName: request && request.OrderName ? request.OrderName : null,
          DateStart: request && request.DateStart ? request.DateStart : null,
          DateEnd: request && request.DateEnd ? request.DateEnd : null
        })
        .then((response) => {
          if (response.data.length >= 0) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('取得設計資料列表發生錯誤！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleteDesignProject({ rootGetters }, request) {
      return axios
        .post('DeleteDesignProject', {
          DesignOrderGuids: request.DesignOrderGuids
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESDesignOrderInfo({ commit, rootGetters }, request) {
      return axios
        .post('DesignOrderInfo', {
          DesignOrderGuid: request.DesignOrderGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('存取失敗')
          }
        })
    },
    EstimateRuleList({ commit, rootGetters }, request) {
      return axios
        .post('EstimateRuleList', {
          ManufacturerID: request.ManufacturerID
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadDecorationQuotation({ rootGetters }, request) {
      return axios
        .post(
          'DownloadDecorationQuotation',
          {
            DecorationQuoteRecordId: request.DecorationQuoteRecordId,
            EstimateID: request.EstimateID,
            Gpm: request.Gpm,
            OrderName: request.OrderName,
            OrderTime: request.OrderTime
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrdersSummary({ rootGetters }, request) {
      return axios
        .post('ManufacturerOrdersSummary', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('獲取 Order 庫失敗')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MESOrdersRepoList({ rootGetters }, request) {
      return axios
        .post('GetOrderRepoList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('獲取 Order 庫失敗')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
