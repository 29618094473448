import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    ActivityDate({ commit, rootGetters }, request) {
      return axios
        .post('ActivityDate', {
          ActivityStart: request.ActivityStart,
          ActivityEnd: request.ActivityEnd
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ActivityList({ commit, rootGetters }, request) {
      return axios
        .post('ActivityList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ActivityRead({ commit, rootGetters }, request) {
      return axios
        .post('ActivityRead', {
          ActivityGuid: request.ActivityGuid
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ActivityFile({ commit, rootGetters }, request) {
      return axios
        .post(
          'ActivityFile',
          {
            ActivityFileGuid: request.ActivityFileGuid
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ActivityApply({ commit, rootGetters }, request) {
      return axios
        .post('ActivityApply', {
          ActivityGuid: request.ActivityGuid,
          UserName: request.UserName,
          UserMobile: request.UserMobile,
          UserCompany: request.UserCompany,
          UserTitle: request.UserTitle,
          UserEmail: request.UserEmail,
          UserCount: request.UserCount,
          ContactSales: request.ContactSales,
          UserSoftware: request.UserSoftware,
          UserPurpose: request.UserPurpose,
          UserVisit: request.UserVisit,
          ApplyRemark: request.ApplyRemark
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
