import { axiosInstance as axios } from '../../boot/axios'
import { axiosInstance as axiosMes } from '../../boot/axios-mes'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 製造商取得自動倉儲狀態POST
    GetStorage({ rootGetters }) {
      return axios
        .post('GetStorage', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 製造商取得餘料倉儲狀態
    GetReuseStorage({ rootGetters }) {
      return axios
        .post('GetReuseStorage', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WorkOrderList({ rootGetters }, request) {
      return axios
        .post('WorkOrderList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleteWorkOrder({ rootGetters }, request) {
      return axios
        .post('DeleteWorkOrder', {
          WorkOrderId: request.WorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WorkOrderDetail({ rootGetters }, request) {
      return axios
        .post('WorkOrderDetail', {
          WorkOrderId: request.WorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetAiCheckResult({ rootGetters }, request) {
      return axios
        .post('GetAiCheckResult', {
          WorkOrderBoardId: request.WorkOrderBoardId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetManualAiCheckResult({ rootGetters }, request) {
      return axios
        .post('GetManualAiCheckResult', {
          WorkOrderBoardId: request.WorkOrderBoardId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ManualWorkOrderList({ rootGetters }, request) {
      return axios
        .post('ManualWorkOrderList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ManualModifWorkOrderStatus({ rootGetters }, request) {
      //手动修改半自动线工單状态
      return axios
        .post('ManualModifWorkOrderStatus', {
          WorkOrderId: request.WorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ModifWorkOrderStatus({ rootGetters }, request) {
      //手动修改自动线工單状态
      return axios
        .post('ModifWorkOrderStatus', {
          WorkOrderId: request.WorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DeleteManualWorkOrder({ rootGetters }, request) {
      return axios
        .post('DeleteManualWorkOrder', {
          ManualWorkOrderId: request.ManualWorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ManualWorkOrderDetail({ rootGetters }, request) {
      return axios
        .post('ManualWorkOrderDetail', {
          ManualWorkOrderId: request.ManualWorkOrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BoardPackingList({ rootGetters }, request) {
      return axios
        .post('BoardPackingList', {
          OrderId: request.OrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MetalPackingList({ rootGetters }, request) {
      return axios
        .post('MetalPackingList', {
          OrderId: request.OrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取工作站点料记录
    GetWorkStationPackingList({ rootGetters }, request) {
      return axios
        .post('GetWorkStationPackingList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取工廠和工作站
    GetWorkStationAndFactory({ rootGetters }, request) {
      return axios
        .post('GetWorkStationAndFactory', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //添加工作站点料记录
    AddWorkStationPacking({ rootGetters }, request) {
      return axios
        .post('AddWorkStationPacking', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取板材信息
    GetPackingBoardDataList({ rootGetters }, request) {
      return axios
        .post('GetPackingBoardDataList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SavePackingBoard({ rootGetters }, request) {
      return axios
        .post('SavePackingBoard', {
          OrderId: request.OrderId,
          BoardIds: request.BoardIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('板材已完成點料，已重新獲取數據')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('訂單不包含此板材')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SavePackingMetal({ rootGetters }, request) {
      return axios
        .post('SavePackingMetal', {
          OrderId: request.OrderId,
          Metals: request.Metals
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //取消点料
    DelePackingBoard({ rootGetters }, request) {
      return axios
        .post('DelePackingBoard', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //點料重置
    DeleAllPackingBoard({ rootGetters }, request) {
      return axios
        .post('DeleAllPackingBoard', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //匯出Excel
    ReportBoardPackingExcel({ rootGetters }, request) {
      let formData = new FormData()
      formData.append('OrderId', request.OrderId)
      return axios
        .post('ReportBoardPackingExcel', formData, { responseType: 'blob' })
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CheckFinished({ rootGetters }, request) {
      return axios
        .post('CheckFinished', {
          OrderId: request.OrderId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('訂單不存在')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('點料數量錯誤')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('點料內容與記錄不符合')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardList({ rootGetters }, request) {
      return axiosMes
        .post('RawBoardList', {
          SearchCondition: request.SearchCondition,
          ItemPerPage: request.ItemPerPage,
          PageNo: request.PageNo
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardAdd({ rootGetters }, request) {
      const formData = new FormData()

      formData.append('RawBoardSN', request.RawBoardSN)
      formData.append('Material', request.Material)
      formData.append('Color', request.Color)
      formData.append('Width', request.Width)
      formData.append('Length', request.Length)
      formData.append('Thickness', request.Thickness)
      formData.append('RawBoardImage', request.RawBoardImage)
      return axiosMes
        .post('RawBoardAdd', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('原料板重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardRead({ rootGetters }, request) {
      return axiosMes
        .post('RawBoardRead', {
          RawBoardId: request.RawBoardId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardModify({ rootGetters }, request) {
      const formData = new FormData()

      formData.append('RawBoardId', request.RawBoardId)
      formData.append('RawBoardSN', request.RawBoardSN)
      formData.append('Material', request.Material)
      formData.append('Color', request.Color)
      formData.append('Width', request.Width)
      formData.append('Length', request.Length)
      formData.append('Thickness', request.Thickness)
      formData.append('RawBoardImage', request.RawBoardImage)

      return axiosMes
        .post('RawBoardModify', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('原料板重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardDelete({ rootGetters }, request) {
      return axiosMes
        .post('RawBoardDelete', {
          RawBoardIds: request.RawBoardIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    RawBoardImport({ rootGetters }, request) {
      let formData = new FormData()

      formData.append('RawBoardExcelFile', request.RawBoardExcelFile)

      return axiosMes
        .post('RawBoardImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('原料板重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案中有重複原料板')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleAdd({ rootGetters }, request) {
      return axiosMes
        .post('BandingStripleAdd', {
          BandingStripleSN: request.BandingStripleSN,
          Color: request.Color,
          Thickness: request.Thickness,
          ThickOrThin: request.ThickOrThin
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('封邊條重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleRead({ rootGetters }, request) {
      return axiosMes
        .post('BandingStripleRead', {
          BandingStripleId: request.BandingStripleId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleModify({ rootGetters }, request) {
      return axiosMes
        .post('BandingStripleModify', {
          BandingStripleId: request.BandingStripleId,
          BandingStripleSN: request.BandingStripleSN,
          Color: request.Color,
          Thickness: request.Thickness,
          ThickOrThin: request.ThickOrThin
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('封邊條重複')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleDelete({ rootGetters }, request) {
      return axiosMes
        .post('BandingStripleDelete', {
          BandingStripleIds: request.BandingStripleIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleList({ rootGetters }, request) {
      return axiosMes
        .post('BandingStripleList', {
          SearchCondition: request.SearchCondition,
          ItemPerPage: request.ItemPerPage,
          PageNo: request.PageNo
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    BandingStripleImport({ rootGetters }, request) {
      const formData = new FormData()

      formData.append('BandingStripleExcelFile', request.BandingStripleExcelFile)

      return axiosMes
        .post('BandingStripleImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('封邊條重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('檔案中有重複封邊條')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectAdd({ rootGetters }, request) {
      return axios
        .post('InternalDefectAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectRead({ rootGetters }, request) {
      return axios
        .post('InternalDefectRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectModify({ rootGetters }, request) {
      return axios
        .post('InternalDefectModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectDelete({ rootGetters }, request) {
      return axios
        .post('InternalDefectDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectList({ rootGetters }, request) {
      return axios
        .post('InternalDefectList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectRecordAdd({ rootGetters }, request) {
      return axios
        .post('InternalDefectRecordAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectRecordRead({ rootGetters }, request) {
      return axios
        .post('InternalDefectRecordRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectRecordModify({ rootGetters }, request) {
      return axios
        .post('InternalDefectRecordModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    InternalDefectRecordDelete({ rootGetters }, request) {
      return axios
        .post('InternalDefectRecordDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectAdd({ rootGetters }, request) {
      return axios
        .post('ExternalDefectAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('輸入的訂單編號無效')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectRead({ rootGetters }, request) {
      return axios
        .post('ExternalDefectRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectModify({ rootGetters }, request) {
      return axios
        .post('ExternalDefectModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectDelete({ rootGetters }, request) {
      return axios
        .post('ExternalDefectDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectList({ rootGetters }, request) {
      return axios
        .post('ExternalDefectList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectRecordAdd({ rootGetters }, request) {
      return axios
        .post('ExternalDefectRecordAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectRecordRead({ rootGetters }, request) {
      return axios
        .post('ExternalDefectRecordRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectRecordModify({ rootGetters }, request) {
      return axios
        .post('ExternalDefectRecordModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExternalDefectRecordDelete({ rootGetters }, request) {
      return axios
        .post('ExternalDefectRecordDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetProduction({ rootGetters }, request) {
      return axios
        .post('GetProduction', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('獲取 Mes 站台失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('獲取產量失敗')
          } else if (response.data.Values.ReqInt === -11) {
            return Promise.reject('缺少開始或結束時間')
          } else if (response.data.Values.ReqInt === -12) {
            return Promise.reject('開始小於結束時間')
          } else if (response.data.Values.ReqInt === -21) {
            return Promise.reject('無設備產量資訊')
          } else if (response.data.Values.ReqInt === -30) {
            return Promise.reject('無設備列表')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetAvailability({ rootGetters }, request) {
      return axios
        .post('GetAvailability', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('獲取 Mes 站台失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('獲取稼動率失敗')
          } else if (response.data.Values.ReqInt === -11) {
            return Promise.reject('缺少開始或結束時間')
          } else if (response.data.Values.ReqInt === -12) {
            return Promise.reject('開始小於結束時間')
          } else if (response.data.Values.ReqInt === -21) {
            return Promise.reject('無設備稼動率資訊')
          } else if (response.data.Values.ReqInt === -30) {
            return Promise.reject('無設備列表')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ManualSpherePacking({ rootGetters }, request) {
      let formData = new FormData()

      formData.append('CsvFile', request.CsvFile)

      return axios
        .post('ManualSpherePacking', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('無對應原料板')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('原料板數量不足')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('小板長度過長')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadWorkOrderFile({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadWorkOrderFile',
          {
            WorkOrderId: request.WorkOrderId
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MwcConfigSet({ rootGetters }, request) {
      return axios
        .post('MwcConfigSet', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MwcConfigRead({ rootGetters }) {
      return axios
        .post('MwcConfigRead', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    DownloadManualWorkOrderFile({ commit, rootGetters }, request) {
      return axios
        .post(
          'DownloadManualWorkOrderFile',
          {
            ManualWorkOrderId: request.ManualWorkOrderId
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // #region MachineFault
    MachineFaultAdd({ rootGetters }, request) {
      return axios
        .post('MachineFaultAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultRead({ rootGetters }, request) {
      return axios
        .post('MachineFaultRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultModify({ rootGetters }, request) {
      return axios
        .post('MachineFaultModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultDelete({ rootGetters }, request) {
      return axios
        .post('MachineFaultDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取異常補料列表
    GetAbnormalBoardRecordList({ rootGetters }, request) {
      return axios
        .post('GetAbnormalBoardRecordList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //添加異常補料表
    AddAbnormalBoardRecord({ rootGetters }, request) {
      return axios
        .post('AddAbnormalBoardRecord', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //修改異常補料表
    SetAbnormalBoardRecord({ rootGetters }, request) {
      return axios
        .post('SetAbnormalBoardRecord', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取異常補料訂單詳細信息
    GetAbnormalBoardRecordInfo({ rootGetters }, request) {
      return axios
        .post('GetAbnormalBoardRecordInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //根據板材獲取訂單信息
    GetBoardSNOrderInfo({ rootGetters }, request) {
      return axios
        .post('GetBoardSNOrderInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //根據板材獲取訂單信息
    ReportAbnormalBoardExcel({ rootGetters }, request) {
      return axios
        .post(
          'ReportAbnormalBoardExcel',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          if (response.data.Values && response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else {
            return Promise.resolve(response.data)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultList({ rootGetters }, request) {
      return axios
        .post('MachineFaultList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultRecordAdd({ rootGetters }, request) {
      return axios
        .post('MachineFaultRecordAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultRecordRead({ rootGetters }, request) {
      return axios
        .post('MachineFaultRecordRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultRecordModify({ rootGetters }, request) {
      return axios
        .post('MachineFaultRecordModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    MachineFaultRecordDelete({ rootGetters }, request) {
      return axios
        .post('MachineFaultRecordDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // #endregion

    // #region Expense
    ExpenseTypeAdd({ rootGetters }, request) {
      return axios
        .post('ExpenseTypeAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('類別名稱重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('顯示順序重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExpenseTypeRead({ rootGetters }, request) {
      return axios
        .post('ExpenseTypeRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExpenseTypeModify({ rootGetters }, request) {
      return axios
        .post('ExpenseTypeModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('類別名稱重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('顯示順序重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExpenseTypeDelete({ rootGetters }, request) {
      return axios
        .post('ExpenseTypeDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExpenseTypeList({ rootGetters }) {
      return axios
        .post('ExpenseTypeList', {})
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExpenseTypeChangeOrder({ rootGetters }, request) {
      return axios
        .post('ExpenseTypeChangeOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('類別順序重複')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('未傳入所有項目或項目與原先不符')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryExpenseAdd({ rootGetters }, request) {
      return axios
        .post('FactoryExpenseAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('月份與費用類型重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryExpenseRead({ rootGetters }, request) {
      return axios
        .post('FactoryExpenseRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryExpenseModify({ rootGetters }, request) {
      return axios
        .post('FactoryExpenseModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('發生錯誤')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('月份與費用類型重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryExpenseDelete({ rootGetters }, request) {
      return axios
        .post('FactoryExpenseDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve()
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryExpenseList({ rootGetters }, request) {
      return axios
        .post('FactoryExpenseList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
    // #endregion
  }
}
