import { date } from 'quasar'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, email, alpha_num, integer } from 'vee-validate/dist/rules'

export default ({ app, router, store, Vue }) => {
  Vue.component('ValidationProvider', ValidationProvider)
  Vue.component('ValidationObserver', ValidationObserver)

  extend('email', {
    ...email,
    message: '無效的電子郵件格式'
  })

  extend('required', {
    ...required,
    message: '必填'
  })

  extend('min', {
    validate(value, { min }) {
      return value.length >= min
    },
    params: ['min'],
    message: '需要至少{min}個字'
  })

  extend('max', {
    validate(value, { max }) {
      return value.length <= max
    },
    params: ['max'],
    message: '不能超過{max}個字'
  })

  extend('alpha_num', {
    ...alpha_num,
    message: '只能包含英文字母跟數字'
  })

  extend('integer', {
    ...integer,
    message: '必須是整數'
  })

  extend('date_lte_today', {
    validate(value) {
      const today = date.formatDate(new Date(), 'YYYY/MM/DD')
      let inputDate = date.formatDate(value, 'YYYY/MM/DD')
      return inputDate <= today
    },
    message: '必須在今日或之前'
  })

  extend('date_after_today', {
    validate(value) {
      const today = date.formatDate(new Date(), 'YYYY/MM/DD')
      let inputDate = date.formatDate(value, 'YYYY/MM/DD')
      return inputDate > today
    },
    message: '必須在今日之後'
  })

  extend('max_date', {
    validate(value, { max }) {
      if (max) return value <= max
      return true
    },
    params: ['max'],
    message: '必須在前於 {_max_}'
  })

  extend('min_date', {
    validate(value, { min }) {
      if (min) return value >= min
      return true
    },
    params: ['min'],
    message: '必須在後於 {_min_}'
  })

  extend('max_year_month', {
    validate(value, { max }) {
      if (max) return value <= max
      return true
    },
    params: ['max'],
    message: '必須在前於結束時間'
  })

  extend('min_year_month', {
    validate(value, { min }) {
      if (min) return value >= min
      return true
    },
    params: ['min'],
    message: '必須在後於起始時間'
  })

  extend('between_date', {
    validate(value, { min, max }) {
      return value >= min && value <= max
    },
    params: ['min', 'max'],
    message: '必須在 {_min_} 與 {_max_} 之間'
  })

  extend('min_value', {
    validate(value, { min }) {
      return Number(value) >= Number(min)
    },
    params: ['min'],
    message: '至少大於等於 {min}'
  })

  extend('max_value', {
    validate(value, { max }) {
      return Number(value) <= Number(max)
    },
    params: ['max'],
    message: '不可大於 {max}'
  })

  extend('decimal', {
    validate(value, { maxDecimal }) {
      if (value.toString().indexOf('.') === -1) {
        return true
      } else {
        return value.toString().split('.')[1].length <= maxDecimal
      }
    },
    params: ['maxDecimal'],
    message: '最多只允許小數點後 {maxDecimal} 位'
  })

  extend('preserved_words_symbols', {
    validate(value) {
      if (value.toString().indexOf('/') === -1 && value.toString().indexOf('\\') === -1) {
        return true
      } else {
        return false
      }
    },
    message: '不可包含系統保留字元或符號'
  })

  extend('password', (value) => {
    return value.match(/^(?=.*[A-Za-z])(?=.*\d)[\S]{8,}$/) ? true : '至少八個字元，包含一個英文字母、和一個數字'
  })

  extend('password_confirm', {
    params: ['target'],
    validate(value, { target }) {
      return value === target
    },
    message: '密碼不一致'
  })

  extend('cellphone', (value) => {
    return value.match(/^[09]{2}[0-9]{8}$/) ? true : '手機格式不正確(格式：0912345678)'
  })

  extend('username_register', (value) => {
    return value.match(/^[a-zA-Z0-9.]+$/) ? true : '只允許大小英文字母、數字或點'
  })

  extend('username_login', (value) => {
    return value.match(/^[a-zA-Z0-9_.@]+$/) ? true : '只允許大小英文字母、數字或點'
  })

  extend('gt_lower_bound', {
    params: ['lowerBound'],
    validate(value, { lowerBound }) {
      return Number(value) === 0 || Number(lowerBound) === 0 || Number(value) > Number(lowerBound)
    },
    message: '上限必須大於下限'
  })

  extend('lt_upper_bound', {
    params: ['upperBound'],
    validate(value, { upperBound }) {
      return Number(value) === 0 || Number(upperBound) === 0 || Number(value) < Number(upperBound)
    },
    message: '下限必須小於上限'
  })

  extend('is_duplicate', {
    params: ['existingValues', 'type'],
    validate(value, { existingValues, type }) {
      if (type === 'number') {
        return existingValues.filter((val) => val === Number(value)).length === 1
      }

      return existingValues.filter((val) => val === value).length === 1
    },
    message: '欄位值不能重複'
  })

  extend('start_with_zero', value => {
    if (Number.parseInt(value) === 0) return true

    const regex = /^(?!0)\d+(\.\d+)?$/
    return regex.test(value.toString()) ? true : '開頭不可為0'
  })
}
