import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    MainNews({ commit, rootGetters }, request) {
      return axios
        .post('MainNews', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewsList({ commit, rootGetters }, request) {
      return axios
        .post('NewsList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewsRead({ commit, rootGetters }, request) {
      return axios
        .post('NewsRead', {
          NewsGuid: request.NewsGuid
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('訊息公告讀取失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
