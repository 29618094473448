import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    WholeHouseQuoteData({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteData', {

          WholeHouseQuoteId: request.WholeHouseQuoteId,
          IsFactory: request.IsFactory,
          CompanyId: request.CompanyId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('成品報價失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('存取被拒，請登入正確帳號')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteRuleList({ rootGetters }) {
      return axios
        .post('WholeHouseQuoteRuleList')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品報價尺寸加減價規則列表失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteRuleAdd({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteRuleAdd', {

          WholeHouseQuoteRuleName: request.WholeHouseQuoteRuleName,
          LowerBaseHeightLimit: request.LowerBaseHeightLimit,
          UpperBaseHeightLimit: request.UpperBaseHeightLimit,
          LowerBaseWidthLimit: request.LowerBaseWidthLimit,
          UpperBaseWidthLimit: request.UpperBaseWidthLimit,
          LowerBaseDepthLimit: request.LowerBaseDepthLimit,
          UpperBaseDepthLimit: request.UpperBaseDepthLimit,
          HeightMarkupInterval: request.HeightMarkupInterval,
          HeightMarkupPercentage: request.HeightMarkupPercentage,
          HeightMarkdownInterval: request.HeightMarkdownInterval,
          HeightMarkdownPercentage: request.HeightMarkdownPercentage,
          WidthMarkupInterval: request.WidthMarkupInterval,
          WidthMarkupPercentage: request.WidthMarkupPercentage,
          WidthMarkdownInterval: request.WidthMarkdownInterval,
          WidthMarkdownPercentage: request.WidthMarkdownPercentage,
          DepthMarkupInterval: request.DepthMarkupInterval,
          DepthMarkupPercentage: request.DepthMarkupPercentage,
          DepthMarkdownInterval: request.DepthMarkdownInterval,
          DepthMarkdownPercentage: request.DepthMarkdownPercentage,
          MarkupLimit: request.MarkupLimit,
          MarkdownLimit: request.MarkdownLimit
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('新增成品報價尺寸加減價規則失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('規則名稱重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteRuleRead({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteRuleRead', {

          WholeHouseQuoteRuleId: request.WholeHouseQuoteRuleId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品報價尺寸加減價規則失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteRuleModify({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteRuleModify', {

          WholeHouseQuoteRuleId: request.WholeHouseQuoteRuleId,
          WholeHouseQuoteRuleName: request.WholeHouseQuoteRuleName,
          LowerBaseHeightLimit: request.LowerBaseHeightLimit,
          UpperBaseHeightLimit: request.UpperBaseHeightLimit,
          LowerBaseWidthLimit: request.LowerBaseWidthLimit,
          UpperBaseWidthLimit: request.UpperBaseWidthLimit,
          LowerBaseDepthLimit: request.LowerBaseDepthLimit,
          UpperBaseDepthLimit: request.UpperBaseDepthLimit,
          HeightMarkupInterval: request.HeightMarkupInterval,
          HeightMarkupPercentage: request.HeightMarkupPercentage,
          HeightMarkdownInterval: request.HeightMarkdownInterval,
          HeightMarkdownPercentage: request.HeightMarkdownPercentage,
          WidthMarkupInterval: request.WidthMarkupInterval,
          WidthMarkupPercentage: request.WidthMarkupPercentage,
          WidthMarkdownInterval: request.WidthMarkdownInterval,
          WidthMarkdownPercentage: request.WidthMarkdownPercentage,
          DepthMarkupInterval: request.DepthMarkupInterval,
          DepthMarkupPercentage: request.DepthMarkupPercentage,
          DepthMarkdownInterval: request.DepthMarkdownInterval,
          DepthMarkdownPercentage: request.DepthMarkdownPercentage,
          MarkupLimit: request.MarkupLimit,
          MarkdownLimit: request.MarkdownLimit
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('儲存編輯成品報價尺寸加減價規則失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('規則名稱重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteRuleDelete({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteRuleDelete', {

          WholeHouseQuoteRuleId: request.WholeHouseQuoteRuleId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('刪除成品報價尺寸加減價規則失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteItemList({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteItemList', {

          SearchCondition: request.SearchCondition,
          ItemPerPage: request.ItemPerPage,
          PageNo: request.PageNo
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品庫品項列表失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteItemAdd({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteItemAdd', {

          WholeHouseQuoteItemName: request.WholeHouseQuoteItemName,
          MaterialId: request.MaterialId,
          Number: request.Number,
          WholeHouseQuoteType: request.WholeHouseQuoteType,
          WholeHouseQuoteRuleId: request.WholeHouseQuoteRuleId,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Width: request.Width,
          Depth: request.Depth,
          Height: request.Height,
          UnitPrice: request.UnitPrice
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('新增成品庫品項失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('Material ID 或產品編號重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteItemRead({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteItemRead', {

          WholeHouseQuoteItemId: request.WholeHouseQuoteItemId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品庫品項失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteItemModify({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteItemModify', {

          WholeHouseQuoteItemId: request.WholeHouseQuoteItemId,
          WholeHouseQuoteItemName: request.WholeHouseQuoteItemName,
          MaterialId: request.MaterialId,
          Number: request.Number,
          WholeHouseQuoteType: request.WholeHouseQuoteType,
          WholeHouseQuoteRuleId: request.WholeHouseQuoteRuleId,
          MinimumSquareFoot: request.MinimumSquareFoot,
          Width: request.Width,
          Depth: request.Depth,
          Height: request.Height,
          UnitPrice: request.UnitPrice
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('編輯成品庫品項失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('Material ID 或產品編號重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    WholeHouseQuoteItemDelete({ rootGetters }, request) {
      return axios
        .post('WholeHouseQuoteItemDelete', {

          WholeHouseQuoteItemId: request.WholeHouseQuoteItemId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('刪除成品庫品項失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteRuleList({ rootGetters }) {
      return axios
        .post('FactoryWholeHouseQuoteRuleList')
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品報價尺寸加減價規則列表失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteRuleAdd({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteRuleAdd', {

          FactoryWholeHouseQuoteRuleName: request.FactoryWholeHouseQuoteRuleName,
          LowerBaseHeightLimit: request.LowerBaseHeightLimit,
          UpperBaseHeightLimit: request.UpperBaseHeightLimit,
          LowerBaseWidthLimit: request.LowerBaseWidthLimit,
          UpperBaseWidthLimit: request.UpperBaseWidthLimit,
          LowerBaseDepthLimit: request.LowerBaseDepthLimit,
          UpperBaseDepthLimit: request.UpperBaseDepthLimit,
          HeightMarkupInterval: request.HeightMarkupInterval,
          HeightMarkupPercentage: request.HeightMarkupPercentage,
          HeightMarkdownInterval: request.HeightMarkdownInterval,
          HeightMarkdownPercentage: request.HeightMarkdownPercentage,
          WidthMarkupInterval: request.WidthMarkupInterval,
          WidthMarkupPercentage: request.WidthMarkupPercentage,
          WidthMarkdownInterval: request.WidthMarkdownInterval,
          WidthMarkdownPercentage: request.WidthMarkdownPercentage,
          DepthMarkupInterval: request.DepthMarkupInterval,
          DepthMarkupPercentage: request.DepthMarkupPercentage,
          DepthMarkdownInterval: request.DepthMarkdownInterval,
          DepthMarkdownPercentage: request.DepthMarkdownPercentage,
          MarkupLimit: request.MarkupLimit,
          MarkdownLimit: request.MarkdownLimit
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('新增成品報價尺寸加減價規則失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('規則名稱重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteRuleRead({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteRuleRead', {

          FactoryWholeHouseQuoteRuleId: request.FactoryWholeHouseQuoteRuleId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('讀取成品報價尺寸加減價規則失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteRuleModify({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteRuleModify', {

          FactoryWholeHouseQuoteRuleId: request.FactoryWholeHouseQuoteRuleId,
          FactoryWholeHouseQuoteRuleName: request.FactoryWholeHouseQuoteRuleName,
          LowerBaseHeightLimit: request.LowerBaseHeightLimit,
          UpperBaseHeightLimit: request.UpperBaseHeightLimit,
          LowerBaseWidthLimit: request.LowerBaseWidthLimit,
          UpperBaseWidthLimit: request.UpperBaseWidthLimit,
          LowerBaseDepthLimit: request.LowerBaseDepthLimit,
          UpperBaseDepthLimit: request.UpperBaseDepthLimit,
          HeightMarkupInterval: request.HeightMarkupInterval,
          HeightMarkupPercentage: request.HeightMarkupPercentage,
          HeightMarkdownInterval: request.HeightMarkdownInterval,
          HeightMarkdownPercentage: request.HeightMarkdownPercentage,
          WidthMarkupInterval: request.WidthMarkupInterval,
          WidthMarkupPercentage: request.WidthMarkupPercentage,
          WidthMarkdownInterval: request.WidthMarkdownInterval,
          WidthMarkdownPercentage: request.WidthMarkdownPercentage,
          DepthMarkupInterval: request.DepthMarkupInterval,
          DepthMarkupPercentage: request.DepthMarkupPercentage,
          DepthMarkdownInterval: request.DepthMarkdownInterval,
          DepthMarkdownPercentage: request.DepthMarkdownPercentage,
          MarkupLimit: request.MarkupLimit,
          MarkdownLimit: request.MarkdownLimit
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('儲存編輯成品報價尺寸加減價規則失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('規則名稱重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteRuleDelete({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteRuleDelete', {

          FactoryWholeHouseQuoteRuleId: request.FactoryWholeHouseQuoteRuleId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('刪除成品報價尺寸加減價規則失敗')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemList({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteItemList', {

          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemAdd({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteItemAdd', {

          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemRead({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteItemRead', {

          FactoryWholeHouseQuoteItemId: request.FactoryWholeHouseQuoteItemId
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemModify({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteItemModify', {

          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemDelete({ rootGetters }, request) {
      return axios
        .post('FactoryWholeHouseQuoteItemDelete', {

          FactoryWholeHouseQuoteItemIds: request.FactoryWholeHouseQuoteItemIds
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemImport({ rootGetters }, request) {
      const formData = new FormData()
      formData.append('ExcelFile', request.ExcelFile)
      formData.append('IsOverride', request.IsOverride)

      return axios
        .post('FactoryWholeHouseQuoteItemImport', formData)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject('刪除成品庫品項失敗')
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('必填欄位為空')
          } else if (response.data.Values.ReqInt === -3) {
            return Promise.reject('非法成品報價類別')
          } else if (response.data.Values.ReqInt === -4) {
            return Promise.reject('計價規則不存在')
          } else if (response.data.Values.ReqInt === -5) {
            return Promise.reject('最小才數格式錯誤')
          } else if (response.data.Values.ReqInt === -6) {
            return Promise.reject('檔案內有重複成品')
          } else if (response.data.Values.ReqInt === -7) {
            return Promise.reject('成品重複')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    FactoryWholeHouseQuoteItemExport({ commit, rootGetters }, request) {
      return axios
        .post(
          'FactoryWholeHouseQuoteItemExport',
          {
            ...request
          },
          { responseType: 'blob' }
        )
        .then((response) => {
          return Promise.resolve(response.data)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
