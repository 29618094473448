import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    CourseList({ commit, rootGetters }, request) {
      return axios
        .post('CourseList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    PopularCourseList({ commit, rootGetters }, request) {
      return axios
        .post('PopularCourseList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AllCourseList({ commit, rootGetters }, request) {
      return axios
        .post('AllCourseList', request)
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CourseRead({ commit, rootGetters }, request) {
      return axios
        .post('CourseRead', {
          VideoGuid: request.VideoGuid
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    VideoLike({ commit, rootGetters }, request) {
      return axios
        .post('VideoLike', {
          VideoGuid: request.VideoGuid,
          VideoLike: request.VideoLike
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve('成功！')
          } else {
            return Promise.reject('失敗！')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
